import React from "react";
import { Link } from "react-router-dom";
import instance from "../../axiosConfig";
import propTypes from "prop-types";

function RouterLink(props) {
  const download = (link) => {
    link.preventDefault();
    const href = link.target.href;
    const linkFileName = link.target.download;
    instance
      .get(href, {
        responseType: "blob",
      })
      .then((response) => {
        // create file link in browser's memory
        let filename = "";
        if (!linkFileName) {
          filename = response.request.responseURL.split("/").pop();
        } else {
          filename = linkFileName;
        }

        filename = filename.split("?")[0];

        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename); // or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((response) => {
        console.error("Error in download", response);
      });
  };

  if (
    props.href.split("?")[0].endsWith(".pdf") ||
    props.href.split("?")[0].endsWith(".png") ||
    props.href.split("?")[0].endsWith(".jpg") ||
    props.href.split("?")[0].endsWith(".jpeg") ||
    props.href.split("?")[0].endsWith(".csv") ||
    props.href.split("?")[0].endsWith(".xml")
  ) {
    if (props.href.startsWith("http") || props.href.startsWith("https")) {
      return (
        <a
          href={props.href}
          className="dark:text-blue-300"
          target="_blank"
          rel="noreferrer"
        >
          {props.children}
        </a>
      );
    }

    const base = window.localStorage.getItem("baseURL") + "/api";
    return (
      <a
        href={base + props.href}
        className="dark:text-blue-300"
        download={props.download}
        onClick={download}
      >
        {props.children}
      </a>
    );
  }

  return props.href.match(/^(https?:)?\/\//) ? (
    <a href={props.href} className="dark:text-blue-300">
      {props.children}
    </a>
  ) : (
    <Link to={props.href} className="dark:text-blue-300">
      {props.children}
    </Link>
  );
}

RouterLink.propTypes = {
  href: propTypes.string,
  children: propTypes.array,
  download: propTypes.string,
};
export default RouterLink;
