import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import instance from "../../axiosConfig";
import Button from "../interactive/Button";
import { XCircle } from "react-feather";
import Image from "../image/Image";
import logo from "../../media/logo/logo-green.png";

const FieldFile = ({
  type,
  name,
  value,
  label,
  message,
  disabled,
  required,
  accept,
  multiple,
  endpoint,
  maxSize,
  ...props
}) => {
  const id = nanoid();
  const [newValue, setNewValue] = useState([]);
  const [uploadState, setUploadState] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [fileTooBig, setFileTooBig] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [fileCount, setFileCount] = useState(0);

  const handleChange = async (e) => {
    e.preventDefault();

    if (!multiple) {
      setNewValue([]);
    }
    const files = e.target.files;
    setFileCount(files.length);

    setUploadFailed(false);
    setUploadState(true);
    setUploadedFileCount(0);
    setUploadProgress(0);

    const promises = [];
    for (const file of Array.from(files)) {
      promises.push(upload(file, file.name));
    }

    await Promise.all(promises).then(() => {
      console.log("all done");
      setUploadState(false);
      if (uploadFailed) {
        setNewValue([]);
      }
    });
  };

  useEffect(() => {
    const progress = (100 / fileCount) * uploadedFileCount;
    // console.log("filecount " + fileCount)
    // console.log("uploadedFileCount " + uploadedFileCount)
    // console.log("progress " + progress)
    setUploadProgress(progress);
  }, [uploadedFileCount]);

  useEffect(() => {
    if (typeof value === "string") {
      setNewValue([value]);
    } else {
      setNewValue([...[], ...value]);
    }
  }, [value]);

  const upload = (file, name) => {
    setFileTooBig(false);
    // 10 000 000 = 10mb
    if (file.size > maxSize) {
      return setFileTooBig(true);
    }

    const nameParts = name.split(".");
    const form = new window.FormData();
    form.append("file", file, nanoid() + "." + nameParts[nameParts.length - 1]);
    /** Custom axios config, longer timeout & multipart */
    return instance({
      method: endpoint["hydra:method"],
      data: form,
      url: endpoint["@id"],
      timeout: 260000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        const name = response.data.names[0];
        setUploadedFileCount((prev) => prev + 1);
        setNewValue((arr) => {
          arr = arr.filter((elem) => elem != null);
          arr = arr.filter((elem) => elem !== "");
          return [...arr, ...[name]];
        });
        return true;
      })
      .catch((error) => {
        setUploadFailed(true);
        return false;
      });
  };

  const removeFromList = (e, val) => {
    e.preventDefault();
    // const newList = list.filter(value => value !== val)
    // setList(newList)
    setNewValue((arr) => {
      arr = arr.filter((elem) => elem != null);
      arr = arr.filter((elem) => elem !== "");
      arr = arr.filter((value) => value !== val);
      return [...arr];
    });
  };

  const image = (item) => {
    if (!item) return;
    if (item.endsWith(".pdf")) {
      return <div>{item}</div>;
    }
    if (item.startsWith("http://") || item.startsWith("https://")) {
      return (
        <div className="dark:bg-zinc-500">
          <Image source={item} size={{ width: 50, height: 50 }} />
        </div>
      );
    }
  };

  return (
    <div className="field" key={props.id}>
      {label ? (
        <label htmlFor={id} className="dark:text-white">
          {label}:
        </label>
      ) : null}
      {fileTooBig && (
        <h1 className="dark:text-white">
          Tiedosto on liian suuri lähetettäväksi.
        </h1>
      )}
      {uploadState && (
        <div className="absolute w-full h-full top-0 left-0 z-10 p-2 bg-white dark:bg-gray-700">
          <div className="flex h-screen justify-center items-center flex-col">
            <div className="w-full">
              <h1 className="dark:text-white">
                Tiedostoa / Tiedostoja lähetetään.
              </h1>
            </div>
            <div className="w-3/4 bg-gray-200 rounded-full h-6 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-6 rounded-full"
                style={{ width: uploadProgress + "%" }}
              ></div>
            </div>
            <div className="pl-4 flex flex-wrap">
              {newValue.map((item, index) => (
                <div key={index} className="p-1">
                  <button>
                    <div className="inline-flex ml-2 text-xs items-center font-bold leading-sm uppercase bg-red-200 dark:bg-red-200 text-red-700 rounded-full">
                      {image(item)}
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {uploadFailed && (
        <h1 className="dark:text-white">
          Tiedoston / tiedostojen tallennus epäonnistui.
        </h1>
      )}
      <input type="hidden" value={newValue} name={name} id={id} />
      <div className="flex">
        <div className="relative" style={{ top: "6px" }}>
          {newValue && (
            <Button
              feel="danger"
              type="button"
              text="Poista"
              onClick={(e) => {
                e.preventDefault();
                setNewValue([]);
              }}
            />
          )}
        </div>

        <div className="w-64 pl-4 min-w-60">
          <input
            type={type}
            accept={accept}
            multiple={multiple}
            disabled={disabled}
            required={required}
            onChange={handleChange}
            className="dark:bg-zinc-700"
          />
        </div>

        {multiple === false && <div className="pl-4">{image(newValue[0])}</div>}
        {multiple === true && (
          <div className="pl-4 flex flex-wrap">
            {newValue.map((item, index) => (
              <div key={index} className="p-1">
                <button onClick={(e) => removeFromList(e, item)}>
                  <div className="inline-flex ml-2 text-xs items-center font-bold leading-sm uppercase bg-red-200 text-red-700 rounded-full">
                    {image(item)}
                    <XCircle className="m-0.5" />
                  </div>
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      {message ? <p className="dark:text-white">{message}</p> : null}
    </div>
  );
};

FieldFile.propTypes = {
  id: PropTypes.any,
  type: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  endpoint: PropTypes.object,
  maxSize: PropTypes.string,
};

FieldFile.defaultProps = {
  type: "file",
  label: "Filename: ",
  message: "Select file.... to do something.",
  required: false,
  disabled: false,
  accept: "",
  maxSize: "10000000",
  multiple: false,
  endpoint: {
    "@type": "hydra:Operation",
    "@id": "/attachment/new/item:MUSTA-81C7",
    "schema:name": "NewAttachment",
    "hydra:title": "Upload new file",
    "hydra:method": "PUT",
  },
};

export default FieldFile;
